<template>
  <div class="card" style="min-height: calc(100vh - 158px)">
    <div class="bg-blue-light p-2">
      <TitleButton :showBtn="false" btnTitle="Add New" title="Day Book" />

      <DateQuerySetter @onClickGo="getDateWiseDaybook">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Party: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                    <i class="fas fa-search"></i>
                </span>
          </div>

          <AsyncSelect
            placeholder="Select Party"
            v-model="contactProfile"
            :api-service="fetchContactProfiles"
            :format-label="formatPatientLabel"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Business</label>
          <v-select
            placeholder="Select Business"
            class="w-100"
            :options="businesses"
            label="name"
            :reduce="(name) => name.id"
            v-model="business_id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Voucher Type</label>
          <select class="form-control" v-model="voucher_type">
            <option :value="null" selected>Select voucher type</option>
            <option
              :value="voucherType"
              v-for="voucherType in Object.keys(homeData.voucher_type)"
            >
              {{ homeData.voucher_type[voucherType] }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">txn Type</label>
          <select class="form-control" v-model="txn_type">
            <option :value="null" selected>Select txn type</option>
            <option :value="txnType" v-for="txnType in typeKeys">
              {{ homeData.txn_type[txnType] }}
            </option>
          </select>
        </div>
      </DateQuerySetter>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex justify-content-end px-2">
        <button
          @click="exportTable"
          class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="px-2 pb-5">
      <DayBookTable :dayBooks="dayBooks.data" />
    </div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
        :offset="offset"
        @onPageChange="onPageChange"
        ref="pagination"
      />
    </div>
    <ContactDropdownFilter
      v-if="store.state.isModalOpenThree"
      @onSearchContact="onSearchContact"
      :isFilterable="true"
      :companyRoles="companyRoles"
    />
    <Loader v-if="reportLoading" />
  </div>
</template>

<script>
import handleReport from "@/services/modules/accountingReport";

import { inject } from "vue";
// import { useStore } from 'vuex'
// import { computed } from 'vue'
import {mapMutations, useStore} from "vuex";

import TitleButton from "@/components/atom/TitleButton";
import DateQuerySetter from "@/components/atom/DateQuerySetter";
import DayBookTable from "@/components/molecule/company/dayBook/DayBookTable";
import Pagination from "@/components/atom/Pagination";
import Loader from "@/components/atom/LoaderComponent";
import handleContact from "@/services/modules/contact";
import handleCBusinesses from "@/services/modules/businesses";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleRole from "@/services/modules/role";

export default {
  name: "Daybook",

  components: {
    ContactDropdownFilter,
    AsyncSelect,
    TitleButton,
    DateQuerySetter,
    DayBookTable,
    Pagination,
    Loader,
  },

  data: () => ({
    dayBooks: { data: [] },
    homeData: {
      txn_type: {},
      voucher_type: {},
    },
    contactProfiles: [],
    businesses: [],
    contact_profile_id: null,
    business_id: null,
    voucher_type: null,
    txn_type: null,
    offset: 20,
    contactProfile: null,
    companyRoles: []
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    start() {
      return this.$route.query.start;
    },
    end() {
      return this.$route.query.end;
    },
    token() {
      return localStorage.getItem("token");
    },
    page() {
      return this.$route.query.page;
    },
    typeKeys() {
      return Object.keys(this.homeData.txn_type);
    },
  },

  methods: {
    ...mapMutations({
      setPaginationData: "setPaginationData",
      resetPagination: "resetPagination",
    }),
    getQuery() {
      let query = "?company_id=" + this.companyId;
      if (!this.start && !this.end) return query;
      if (this.start) query += "&start_date=" + this.start;
      if (this.end) query += "&end_date=" + this.end;
      if (this.page) query += "&page=" + this.page;
      if (this.contactProfile) query += "&contact_profile_id=" + this.contactProfile.id;
      if (this.business_id) query += "&business_id=" + this.business_id;
      if (this.txn_type) query += "&txn_type=" + this.txn_type;
      if (this.voucher_type) query += "&voucher_type=" + this.voucher_type;
      query += "&offset=" + this.offset;
      return query;
    },

    exportTable() {
      let url = `${process.env.VUE_APP_BASE_URL}/export/reports/day-book?company_id=${this.companyId}&start_date=${this.start}&end_date=${this.end}&_token=${this.token}`;
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.click();
    },

    getDateWiseDaybook() {
      let query = this.getQuery();
      this.getDaybook(query);
    },

    setPagination() {
      if (!this.dayBooks?.data) return;
      this.setPaginationData({
        records: this.dayBooks.total,
        perPage: this.dayBooks.per_page,
        currentPage: this.dayBooks.current_page,
      });
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = page;
      this.$router.push({ path: this.$route.path, query: routeQuery });
      setTimeout(() => {
        this.getDateWiseDaybook();
      }, 100);
    },

    async getDaybook(query = `?company_id=${this.$route.params.companyId}`) {
      // return;
      try {
        this.reportLoading = true;
        let res = await this.fetchDaybook(query);
        if (!res.status) {
          //this.showError(res.message)
          this.resetPagination();
          this.dayBooks = { data: [] };
        }
        if (res.status) {
          this.dayBooks = res.data;
          this.setPagination();
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.reportLoading = false;
      }
    },

    async getHome() {
      try {
        let res = await this.fetchHome();
        if (!res.status) {
          //this.showError(res.message)
          this.resetPagination();
          this.homeData = {};
        }
        if (res.status) {
          this.homeData = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      }
    },
    async getContactProfiles() {
      try {
        let companyId = this.$route.params.companyId;
        let contactId = "";
        let q = "";
        let query =
          "?company_id=" + companyId + "&q=" + q + "&contact_id" + contactId;
        let res = await this.fetchContactProfiles(query);

        if (res.status) {
          this.contactProfiles = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
      } finally {
        this.loading = false;
      }
    },
    async getBusinesses() {
      try {
        let companyId = this.$route.params.companyId;
        let res = await this.fetchBusinessList("?company_id=" + companyId);

        if (res.status) {
          this.businesses = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },
    onOpenContactSearchModal () {
      this.$store.state.isModalOpenThree = true;
    },
    async getCompanyRoles () {
      const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
      if (res.status) {
        this.companyRoles = res.data
      }
    },
    onSearchContact (contact) {
      this.contactProfile = contact
    }
  },

  setup() {
    const showError = inject("showError");
    const showSuccess = inject("showSuccess");
    const store = useStore()

    const { fetchDaybook, fetchHome, reportLoading } = handleReport();

    const { fetchContactProfiles } = handleContact();

    const { fetchBusinessList } = handleCBusinesses();
    const { formatPatientLabel } = useAsyncDropdownHelper();
    const { fetchCompanyDefaultRoles } = handleRole();

    return {
      // journals: computed(() => store.state.journal.journals),
      fetchContactProfiles,
      fetchBusinessList,
      fetchDaybook,
      fetchHome,
      showError,
      reportLoading,
      showSuccess,
      fetchCompanyDefaultRoles,
      store,
      formatPatientLabel
    };
  },

  mounted() {
    this.getDateWiseDaybook();
    this.getHome();
    this.getContactProfiles();
    this.getBusinesses();
    this.getCompanyRoles();
  },
};
</script>
