<template>
  <div class="table-responsive table-striped my-1">
    <table class="table">
      <thead class="table-light">
      <tr>
        <th>Date</th>
        <th>Txn Type</th>
        <th>Voucher No</th>
        <th>Account </th>
        <th>Debit</th>
        <th>Credit</th>
        <th>Attachments</th>
        <th>Line Description</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(dayBook,index) in dayBooks" :key="index">
        <td>
          {{ dayBook.date }}
        </td>
        <td>
          {{ dayBook.txn_type }}
        </td>
        <td>
          {{ dayBook.voucher_no }}
        </td>
        <td>
          {{ dayBook.account }}
        </td>
        <td>
          {{ commaFormat(dayBook.debit) }}
        </td>
        <td>
          {{ commaFormat(dayBook.credit) }}
        </td>
        <td>
          <button v-if="dayBook.ledger_master?.attachments?.length > 0" class="btn btn-primary btn-sm"
                  style="cursor: pointer"
                  @click="viewAttachments(dayBook.ledger_master.attachments)"
          >
            <i class="fas fa-eye"></i>
            View
          </button>
          <span v-else class="badge btn-danger">
                        No attachments
                      </span>
        </td>
        <td>
          {{ dayBook.line_description }}
        </td>
      </tr>
      </tbody>
    </table>
    <p v-if="!dayBooks.length" class="mt-5 text-center">No data</p>
    <ViewAttachment
        ref="viewAttachmentRef"
        :attachments="modalAttachments"
    />
  </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
import ViewAttachment from "@/components/molecule/ngo/journal/ViewAttachmentModal"
import {ref} from "vue";
export default {
  name: 'DaybookTable',
  components:{
    ViewAttachment
  },
  props: {
    dayBooks: {
      type: Array
    }
  },
  setup() {
    const {commaFormat} = figureFormatter ()

    const modalAttachments = ref([]);
    const viewAttachmentRef = ref();

    const viewAttachments = (attachments) => {
      modalAttachments.value = [];
      modalAttachments.value = attachments;
      viewAttachmentRef?.value.toggleModal();
    }
    return {
      commaFormat,
      viewAttachments,
      modalAttachments,
      viewAttachmentRef
    }
  },

}
</script>